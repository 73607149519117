body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(rgba(216, 216, 216, 0.7) .1em, transparent .1em), linear-gradient(90deg, rgba(216, 216, 216, 0.7) .1em, transparent .1em);
  background-size: 3em 3em;
}

a {
  color: black;
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

nav {
  box-sizing: border-box;

  position: absolute;
  left: 20px;
  right: 20px;
  top: 20px;

  background: rgba(255, 255, 255, 0.08);
  border: 1px solid #EFEFEF;
  box-shadow: 0px 6px 31.1px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(3.3px);
  border-radius: 62px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

nav a {
  text-decoration: none;
  color: black;
  font-weight: 700;
  font-size: 1.4em;
}



.mainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.card.signIn>a {
  width: 100%;
  text-decoration: none;
  margin-bottom: 2em;
}

.oauthButton {
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #FFE8DA;
  border: 1.5px solid black;
  border-radius: 5px;
  padding: 0.6em 0.8em;
  text-decoration: none;
  transition: 0.2s ease-in-out;
}

.oauthButton:hover {
  cursor: pointer;
  background-color: #ffd2b7;
}

.oauthButton span {
  text-decoration: none;
  font-weight: 600;
  font-size: 1rem;
  letter-spacing: -0.03em;
}

.oauthButton img {
  margin-right: 10px;
  width: 35px;
}

.card {
  border-radius: 20px;
  background-color: white;
  box-shadow: 0px 3px 27px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.6em 2em;
}

.card.signIn h2 {
  width: 100%;
  font-size: 2rem;
  text-align: left;
  margin: 0;
}

.card p{
  margin-top: 0.2em;
  margin-bottom: 2em;
  font-weight: 500;
}

.card .bottomLinks {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin-bottom: 10px;
}

.card .bottomLinks a {
  width: 100px;
}


.menuItems {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 20px;
}

.menuItem {
  height: 200px;
  width: 200px;
  background-size: cover;
}

.menuItem::after {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 29.56%, rgba(0, 0, 0, 0.6) 93.99%),;
}